import React, {
  createContext,
  useEffect,
  useContext,
  useState,
  FC,
  PropsWithChildren,
} from "react"
import _ from "lodash"

import { Service } from "../models/service"
import { Promotion } from "../models/promotion"

export type LineItem = {
  item: Service
  quantity: number
}

interface Cart {
  addToCart(item: Service, quantity: number): void
  removeItem(id: string): void
  updateItemQty(id: string, qty: number): void
  resetCart(): void
  closeCart(): void
  openCart(): void
  addPromo(promo: Promotion): void
  removePromo(id: string): void
  promotions: Promotion[]
  lineItems: LineItem[]
  visible: boolean
}

const CartContext = createContext<Cart>({} as Cart)

const CartContextProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [lineItems, setLineItems] = useState<LineItem[]>([])
  const [promotions, setPromotions] = useState<Promotion[]>([])
  const [visible, setVisible] = useState<boolean>(false)

  const value = {
    addToCart(newItem: Service, quantity: number) {
      setLineItems(lineItems => {
        // check to see if new added item exists
        const foundIndex = _.findIndex(
          lineItems,
          lineItem => lineItem.item.id === newItem.id
        )

        // add only when the items doesn't exist
        if (foundIndex === -1) {
          const newLineItems = [...lineItems, { item: newItem, quantity }]
          localStorage.setItem("lineItems", JSON.stringify(newLineItems))
          return newLineItems
        }
      })
    },
    removeItem(id: string) {
      setLineItems(lineItems => {
        const newLineItems = _.filter(
          lineItems,
          lineItem => lineItem.item.id !== id
        )
        localStorage.setItem("lineItems", JSON.stringify(newLineItems))
        return newLineItems
      })
    },
    updateItemQty(id: string, newQty: number) {
      setLineItems(lineItems => {
        const foundLineItem = _.find(
          lineItems,
          lineItem => lineItem.item.id === id
        )

        foundLineItem.quantity = newQty
        return [...lineItems]
      })
    },
    resetCart() {
      setLineItems([])
      setVisible(false)
    },
    closeCart() {
      setVisible(false)
    },
    openCart() {
      setVisible(true)
    },
    addPromo(newPromo: Promotion) {
      setPromotions(promotions => {
        // check to see if new added item exists
        const foundIndex = _.findIndex(
          promotions,
          promo => promo.id === newPromo.id
        )

        // add only when the items doesn't exist
        if (foundIndex === -1) {
          const newPromotions = [...promotions, newPromo]
          return newPromotions
        }
      })
    },
    removePromo(id: string) {
      setPromotions(promotions => {
        const newPromotions = _.filter(
          promotions,
          promotion => promotion.id !== id
        )
        return newPromotions
      })
    },
    promotions,
    lineItems,
    visible,
  }

  useEffect(() => {
    const li = localStorage.getItem("lineItems")
    setLineItems(JSON.parse(li) || [])
  }, [])

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>
}

const useCart = (): Cart => {
  return useContext(CartContext)
}

export { CartContextProvider, useCart }

export default CartContext
