/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react"
import FacebookMessengerChat from "./src/components/facebook-chat-messenger"
import RootProvider from "./src/context/Root"

import "./src/styles/reset.scss"
import "antd/dist/antd.css"
import "./src/styles/index.scss"

export const wrapPageElement = ({ element }) => {
  return (
    <RootProvider>
      {element}
      <FacebookMessengerChat />
    </RootProvider>
  )
}

export const onRouteUpdate = () => {
  const headerElement = document.getElementById("header")
  if (headerElement) {
    headerElement.scrollIntoView()
  }
}
